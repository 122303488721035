import React, { useEffect, useState } from "react";
import PageHeader from "../components/commons/PageHeader";
import { useParams } from "react-router-dom";
import CONTENT from "../utils/data/HomePage2New.json";
import ServiceDetail1 from "../components/serviceDetail/ServiceDetail1";
// import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function ServiceDetail() {
  const { serviceTitle } = useParams();
  const [contentData, setContentData] = useState(null);
  const [selectedService, setSelectedService] = useState(serviceTitle);
  // const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (serviceTitle) {
      // eslint-disable-next-line eqeqeq
      const content = CONTENT.filter((content) => content.id == serviceTitle);
      setContentData(content[0]);
    }
  }, [serviceTitle]);
  return (
    serviceTitle &&
    contentData && (
      <div className="pb-96">
        <PageHeader
          title={`${
            contentData?.title === "Architecture Design"
              ? "Architecture Design & Urban Planning"
              : contentData?.title
          }`}
        />
        <ServiceDetail1
          content={contentData ? contentData : null}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
      </div>
    )
  );
}

export default ServiceDetail;
