import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProjectData from "../utils/data/projects.json";

function ProjectDetails() {
  const { id } = useParams();
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line eqeqeq
      const data = ProjectData.filter((project) => project.id == id);
      setProjectData(data);
    }
  }, [id]);
  console.log(projectData);
  return (
    projectData && (
      <div className="w-full py-[100px]">
        <div className="w-5/6 mb-6 px-3 md:px-10">
          <Link to="/projects">
            <button className="transition-all ease-linear border border-primary delay-150 duration-300 rounded-xl w-48 h-12 bg-primary text-white font-semibold hover:text-black hover:bg-white">
              Back
            </button>
          </Link>
        </div>
        <div className="flex flex-wrap space-x-4 justify-center mb-6">
          <p>{projectData[0].client && `Client :- ${projectData[0].client}`}</p>
          <p>
            {projectData[0].location &&
              `Location :- ${projectData[0].location}`}
          </p>
          <p>{projectData[0].area && `Area :- ${projectData[0].area}`}</p>
          <p>{projectData[0].flooring && projectData[0].flooring} </p>
        </div>

        <div className="flex flex-col md:flex-row flex-wrap w-full px-4 justify-center">
          <div className="relative">
            <img
              src={projectData[0]?.img}
              alt="img"
              loading="lazy"
              className={`${
                projectData[0].images.length > 0
                  ? "md:h-96 object-contain"
                  : "md:h-[800px]"
              }`}
            />
            <p className="absolute bottom-0 text-xl font-semibold px-3 py-2 w-full bg-primary opacity-90">
              {projectData[0]?.heading}
            </p>
          </div>

          {projectData[0]?.images.map((img) => (
            <div className="relative">
              <img
                src={img.img}
                alt="img"
                className="md:h-96 object-contain"
                loading="lazy"
              />
              <p className="absolute bottom-0 text-xl font-semibold px-3 py-2 w-full bg-primary opacity-90">
                {img.heading}
              </p>
            </div>
          ))}
        </div>
      </div>
    )
  );
}

export default ProjectDetails;
