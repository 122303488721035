import React from 'react'
import Slider from 'react-slick'

function HomePage4New() {
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 4,
    centerMode: true,
    className: 'w-full flex items-center',
  }

  const logos = [
    {
      type: 'img',
      src: '/assets/tseries.png',
    },
    {
      type: 'text',
      text: 'ORIENT MERCHANTS PVT. LTD.',
    },
    {
      type: 'img',
      src: '/assets/nestle.png',
    },
    {
      type: 'img',
      src: '/assets/ifb.jpg',
    },
    {
      type: 'text',
      text: 'NEW ADARSH C.G.H.S. LTD.',
    },
    {
      type: 'img',
      src: '/assets/aptiv.jpg',
    },
    {
      type: 'text',
      src: '/assets/metalTech.png',
      text: 'METALTECH',
    },
    {
      type: 'text',
      src: '/assets/vrl.png',
    },
    {
      type: 'text',
      text: 'STARWIN BEVERAGES LLP',
    },
  ]
  return (
    <div className="py-12 flex flex-col items-center  text-center">
      <p className="text-primary font-bold text-xl tracking-widest">
        OUR CLIENTS
      </p>
      <Slider
        {...slickSettings}
        className="flex justify-center w-[90%] items-center h-48"
      >
        {logos.map((logo, index) => (
          <div className="flex flex-col items-center justify-center h-24">
            <div className="flex flex-col justify-center items-center h-full w-full">
              {logo.src && (
                <img
                  src={logo.src}
                  key={index}
                  alt="img"
                  className="h-24 aspect-video object-contain"
                />
              )}
              {logo.text && (
                <p
                  className={`text-sm font-semibold flex justify-center items-center ${
                    !logo.src && 'bg-black text-white px-4 py-2'
                  }`}
                >
                  {logo.text}
                </p>
              )}
              <p></p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default HomePage4New
