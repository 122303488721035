import React, { useState } from 'react';

const Homepage6 = () => {
  const [teamMember, setTeamMember] = useState(0);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setTeamMember((index) => (index !== 2 ? index + 1 : 0));
  //   }, 7040);

  //   return () => {
  //     clearTimeout(intervalId);
  //   };
  // }, []);
  const TEAM = [
    {
      name: 'Preeti Mehla',
      designation: 'FOUNDER | CHIEF ARCHITECT',
      about1:
        "Preeti Mehla is an architect, founder of Preemade Design Studio, and a fine artist. She has over 6 years' experience in architecture and design. With her knowledge of design and creativity, she has made it possible for anyone to make their own unique masterpiece. She loves to create spaces that are fun, fresh and full of life. Always thinking outside of the box, she's a designer who never lets her creativity be limited by materials.",
      about2:
        'With a mission to create an environment for clients that is both functional and beautiful, She makes spaces harmonious and reflective of the people who occupy them. She combine her knowledge of spatial, material and constructional design with an understanding of psychology in order to create products that are functional, user-centered and sustainable. She is highly skilled designer and curator with a knack for amplifying various spaces including residences, commercial offices, industrial warehouses, retail showrooms & institutional.',
      img: '/assets/team/preeti.jpeg',
    },
    {
      name: 'Mr. Mahavir',
      designation: 'DIRECTOR',
      about1:
        'Mr. Mahavir has over 22 years of experience in the contruction industry. He has worked on avariety of projects including residential homes, commercial buildings and mainly warehousing projects(Industrial). He is known for his attention to detail, his ability to effectively manage construction teams, and on going site works, and his commitment to delivering high-quality projects on time and within budget.',
      img: '/assets/team/mahavir.jpeg',
    },
    {
      name: 'Mr. Deepak',
      designation: 'ADVISOR | DESIGN & STRATEGY',
      about1:
        'As a strategist, Deepak creates the concept narrative that shapes the user experience and defines social spaces and meaningful brand engagements.',
      about2:
        'He began his design career in communications and teaching before expanding into strategy and experience design. He is currently focused on understanding consumers as a means to create meaningful brand engagements through experience design, service design, programming, and the built environment. Deepak has worked with entertainment, retail, and hospitality clients.',
      img: '/assets/team/deepak.JPG',
    },
    {
      name: 'Ar. Kuldeep',
      designation: 'PROJECT ARCHITECT',
      about1:
        'Kuldeep, a quick learner is exceptional at planning, detailing, designing and coordinating projects both for the public and private sectors. His designs follow the golden rule of “form follows function”. His good sense of aesthetics coupled with knowledge of architectural standards and building codes, make his design solutions outstanding and unique. With his background in construction he is always updated with latest building techniques and material installation methods.',
      img: '/assets/team/kuldeep.JPG',
    },
  ];
  return (
    <div className='flex flex-col items-center xl:items-start xl:flex-row lg:space-x-10 relative justify-center py-24'>
      <div className='relative px-10 mt-24 xl:mt-0 hidden xl:block w-1/2 mx-auto'>
        <img
          src='/assets/about.jpeg'
          alt='img'
          className='w-full h-[800px]'
        />
        <div className='absolute h-10 w-24 bg-background2 right-0 top-0'></div>
        <div className='absolute h-10 w-24 bg-background2 right-24 top-10'></div>
        <div className='absolute h-10 w-24 bg-background2 right-0 top-20'></div>
        <div className='absolute h-10 w-24 bg-background2 left-0 bottom-0'></div>
        <div className='absolute h-10 w-24 bg-background2 left-24 bottom-10'></div>
        <div className='absolute h-10 w-24 bg-background2 left-0 bottom-20'></div>
      </div>
      <div className='px-2'>
        <p className='text-primary font-semibold text-xl pl-4'>Our Team</p>
        <p className=' text-gray-400 text-xl w-full pl-4 pr-8 md:w-[650px]'>
          {TEAM[teamMember].about1}
        </p>
        <p className=' text-gray-400 text-xl w-full pl-4 pr-8 md:w-[650px] mt-3'>
          {TEAM[teamMember].about2}
        </p>
        <img src={TEAM[teamMember].img} alt='img' className='mt-10 h-56 pl-4' />
        <p className='mt-3 text-xl font-bold text-primary pl-4'>
          {TEAM[teamMember].name}
        </p>
        <p className='font-bold pl-4'>{TEAM[teamMember].designation}</p>
      </div>
      <div className='flex flex-col items-end justify-end space-y-2 absolute right-4 xl:right-10 top-1/3'>
        {TEAM.map((team, index) => (
          <div
            key={index}
            className={`${
              index === teamMember ? 'bg-primary' : 'bg-background'
            } h-6 w-2 cursor-pointer`}
            onClick={() => setTeamMember(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Homepage6;
