import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CONTENT from '../../utils/data/HomePage2New.json';
import Styles from './HomePage2.module.css';

function HomePage2New() {
  const [active, setActive] = useState(0);
  return (
    <div className='flex flex-col items-center bg-background2 py-10'>
      <p className='text-primary font-semibold'>Services</p>
      <div className='text-4xl font-bold mt-3 text-center mb-6'>
        <p>Services We Offer</p>
      </div>
      <div
        className={`flex flex-col lg:flex-row w-5/6 lg:w-5/6 bg-white lg:flex-wrap md:mt-0 mx-3 md:mx-0`}
      >
        {CONTENT.map((content, index) => (
          <div
            onMouseEnter={() => setActive(index)}
            key={index}
            className={`w-full cursor-pointer justify-between overflow-hidden relative hover:bg-transparent border-r lg:border-r-0 lg:w-1/3 space-x-2 flex flex-col border-b-0 items-center p-7 border-l border-primary border-t ${
              index === 2 || index === 5 ? 'lg:border-r' : ''
            } ${index > 2 ? 'border-b-0 lg:border-b' : ''} ${
              index === 5 ? 'border-b' : ''
            }`}
          >
            {active === index && (
              <img
                src={content.img}
                alt='img'
                className={`${Styles.imageScale} absolute top-0 left-0 w-full h-full brightness-[.4]`}
              />
            )}
            <div className='flex flex-col items-center z-10 space-y-4'>
              <img src={content.logo} alt='logo' />
              <p
                className={`${
                  active === index ? 'text-white' : 'text-primary'
                } font-semibold text-xl`}
              >
                {content.title}
              </p>
              <p
                className={`${
                  active === index ? 'text-gray-50' : 'text-gray-400'
                } text-lg text-center`}
              >
                {content.text}
              </p>
            </div>
            <Link to={`/services/${content.id}`} className="z-20">
              <button className='transition-all z-10 mt-5 ease-linear delay-150 duration-300 rounded-xl w-48 h-12 bg-primary text-white font-semibold hover:text-black hover:bg-white'>
                Learn More
              </button>
            </Link>
          </div>
        ))}
      </div>
      <div className=''></div>
    </div>
  );
}

export default HomePage2New;
