import React from 'react';
import Aboutus2 from '../components/aboutus/AboutUs2';
// import Aboutus4 from '../components/aboutus/Aboutus4';
import PageHeader from '../components/commons/PageHeader';
import Footer from '../components/footer/Footer';
import Homepage6 from '../components/homepage/Homepage6';

const Aboutus = () => {
  return (
    <div className='h-screen'>
      <PageHeader title = "About Us" />
      <Aboutus2 />
      {/* <Aboutus4 /> */}
      <Homepage6 />
      <Footer />
    </div>
  );
};

export default Aboutus;
