import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        className="flex justify-center py-24 object-fill"
        style={{
          backgroundImage: `url("https://html.themexriver.com/Archix/assets/img/bg/footer-bg.jpg")`,
        }}
      >
        <div className="flex flex-col text-center md:text-start items-center md:items-start md:grid md:grid-cols-3 w-full md:w-4/5 lg:w-4/5">
          <div className="flex flex-col pl-3 lg:items-center">
            <div className="space-y-3 text-white mt-4">
              <p className="text-primary font-bold text-xl">Address</p>
              <div>
                <p>Q-69, Basement, Sector 40</p>
                <p>Gurugram, NCR Region</p>
                <p>Haryana, 122001</p>
              </div>
              <p>+91 8506001100</p>
              <p>+91 9958477837</p>
              <p>info@preemadeds.com</p>
              <span>mehlapreeti17@gmail.com</span>
            </div>
          </div>
          <div className="flex flex-col pl-3 lg:items-center">
            <div className="space-y-3 text-white mt-4">
              <p className="text-primary font-bold text-xl">Links</p>
              <p>
                <Link to="/aboutus">About </Link>
              </p>
              <p>
                <Link to="/contactus">Contact </Link>
              </p>
              <p>
                <Link to="/projects">Projects </Link>
              </p>
              {/* <p>FAQ</p>
              <p>Terms & Conditions</p>
              <p>Privacy Policies</p> */}
            </div>
          </div>
          <div className="flex flex-col px-3 lg:items-center">
            <div className="space-y-3 text-white mt-4">
              <p className="text-primary font-bold text-xl">Socials</p>
              <div className="flex items-center space-x-4 mt-6">
                <a
                  href="https://www.facebook.com/preemadeds"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF className="text-primary" />
                </a>
                <a
                  href="https://www.twitter.com/preemadeds/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter className="text-primary" />
                </a>
                <a
                  href="https://www.instagram.com/preemadeds/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="text-primary" />
                </a>
                <a
                  href="https://www.linkedin.com/in/PreeMadeds/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin className="text-primary" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-background h-16 w-full flex justify-center text-white items-center">
        <p>©2022 All rights reserved.</p>
      </div>
    </>
  );
};

export default Footer;
