import React from 'react';

function ErrorPage() {
  return (
    <div className='h-screen w-screen text-5xl text-center text-primary flex flex-col justify-center items-center'>
      <p>Error!</p>
      <p> This page doesn't exist.</p>
    </div>
  );
}

export default ErrorPage;
