import React, { useState } from "react";
import "./Project1.css";
import ProjectContainer from "./ProjectContainer";
import projectData from "../../utils/data/projects.json";

const TABS = [
  "ALL",
  "ARCHITECTURES",
  "INTERIORS",
  "3D'S DESIGN",
  "BUILDING/CONSTRUCTION",
];

const Project1 = () => {
  const [selectedTab, setSelectedTab] = useState("ALL");
  return (
    <div className="w-full py-[100px]">
      <div className="flex flex-col md:flex-row gap-x-20 justify-center w-full px-4 md:px-10 lg:px-0">
        <div>
          <ul
            className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4 text-2xl"
            id="tabs-tab3"
            role="tablist"
          >
            {TABS.map((tab, index) => (
              <li className="nav-item" role="presentation" key={index}>
                <p
                  // href="#tabs-home3"
                  className={`${
                    selectedTab === tab ? "active" : ""
                  } nav-link cursor-pointer w-full block font-medium text-xs leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-primary focus:border-transparent`}
                  color="#F8C784"
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="tab-content" id="tabs-tabContent3">
        <div
          className="tab-pane fade show active"
          id="tabs-home3"
          role="tabpanel"
          aria-labelledby="tabs-home-tab3"
        >
          <div className="container">
            {selectedTab !== "ALL"
              ? projectData
                  .filter((data) => data.type === selectedTab)
                  .map((data, index) => (
                    <ProjectContainer
                      img={data.img}
                      id={data.id}
                      heading={data.heading}
                      client={data.client}
                      location={data.location}
                      area={data.area}
                      flooring={data.flooring && data.flooring}
                      type={data.type}
                      key={index}
                      images={data.images}
                    />
                  ))
              : projectData.map((data, index) => (
                  <ProjectContainer
                    img={data.img}
                    id={data.id}
                    heading={data.heading}
                    client={data.client}
                    location={data.location}
                    area={data.area}
                    flooring={data.flooring && data.flooring}
                    type={data.type}
                    key={index}
                    images={data.images}
                  />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project1;
