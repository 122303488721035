import React from "react";
import { Link } from "react-router-dom";
import "./Project1.css";

function ProjectContainer({
  img,
  heading,
  client,
  location,
  area,
  flooring,
  type,
  images,
  id,
}) {

  return (
    <Link to={`/projects/${id}`} className="box">
      <div className="imgBx">
        <img
          src={img}
          alt="img"
          loading="lazy"
          className="object-cover hover:object-contain"
        />
      </div>
      <div className="content">
        <div className="w-full p-[20px] bg-opacity-40 bg-[#f8c784]" style={{
          background: ''
        }}>
          <h2
            className={`text-white font-bold ${
              type === "BUILDING/CONSTRUCTION" || type === "3D'S DESIGN"
                ? "pb-16"
                : "pb-3"
            }`}
          >
            {heading}
          </h2>
          <p>{client && `Client :- ${client}`}</p>
          <p>{location && `Location :- ${location}`}</p>
          <p>{area && `Area :- ${area}`}</p>
          <p>{flooring && flooring} </p>
        </div>
      </div>
    </Link>
  );
}

export default ProjectContainer;
