import React, { useState } from 'react';
import Styles from './HomePage2.module.css';
import CONTENT from '../../utils/data/HomePage2New.json';

const HomePage2 = () => {
  const [active, setActive] = useState(0);
  return (
    <div className='flex flex-col items-center bg-background2 py-10'>
      <p className='text-primary font-semibold'>Services</p>
      <div className='text-4xl font-bold mt-3 text-center'>
        <p>Services We Offer</p>
      </div>
      <div
        className={`${Styles.homepage2} flex justify-center items-center mt-12`}
      >
        <div
          className={`${Styles.section} flex flex-col lg:flex-row bg-white w-full lg:w-5/6 lg:flex-wrap md:mt-0 mx-3 md:mx-0`}
        >
          {CONTENT.map((content, index) => (
            <div
              onMouseEnter={() => setActive(index)}
              key={index}
              className={`${
                active === index ? 'text-white' : ''
              }  lg:w-1/3 p-3 lg:p-6 flex flex-col justify-between relative overflow-hidden`}
            >
              {active === index && (
                <img
                  src={content.img}
                  alt='img'
                  className={`${Styles.imageScale} absolute top-0 left-0 w-full h-full brightness-[.2]`}
                />
              )}
              <div className={`${Styles.horizontalBorder} bg-primary`}></div>
              <div className={`${Styles.verticalBorder} bg-primary`}></div>
              <div className='mt-10 flex justify-center z-10'>
                <img src={content.logo} alt='logo' />
              </div>
              <p className='font-bold text-xl flex justify-center text-center z-10 mt-10 lg:mt-0'>
                {content.title}
              </p>
              <p
                className={`${
                  active === index ? 'text-gray-50' : 'text-gray-400'
                } flex flex-col pl-6 mt-10 lg:mt-4 z-10 `}
              >
                {content.description.map((des, i) => (
                  <span className='flex space-x-1 font-medium' key={i}>
                    <span className='font-semibold'>{i + 1}.</span>{' '}
                    <span>{des}</span>
                  </span>
                ))}
              </p>
              <p className='text-primary text-6xl font-bold flex justify-end mt-10 lg:mt-0 z-10'>
                0{index + 1}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage2;
