import React from "react";
import CountUp from "react-countup";
// import { Link } from 'react-router-dom';
import Styles from "../homepage/HomePage3.module.css";

const AboutUs2 = () => {
  return (
    <div className="mt-24 flex flex-col items-center xl:items-center xl:flex-row lg:space-x-24 relative justify-center mb-10 lg:items-center">
      <div className="absolute -top-10 left-1/3">
        <img
          src="https://html.themexriver.com/Archix/assets/img/bg/ab-bg.png"
          alt="img"
        />
      </div>
      <div className="text-center px-2">
        <p className="text-primary font-semibold">ABOUT PreeMade</p>
        <div className="text-3xl font-bold mt-7  w-full lg:w-[500px]">
          <p>Preemade Design Studio</p>
          <p>
            Specializes in the production of customized and personalized
            products.
          </p>
        </div>
        <p className={`${Styles.textwidth} text-gray-400 text-lg w-96 mt-7`}>
          PreeMade Design Studio is a Gurugram based architecture firm
          established in 2014 by Preeti Mehla, At PreeMade Design Studio, we
          believe that design is not a one-time event, but rather a process. It
          is a dialogue between the designer and the client that takes place
          over time and enabled by a space where we indulge in our creativity.
          We believe in creating spaces that are beautiful and meaningful, that
          are simple and sustainable to suit our clients’ needs. The design
          studio is a well established practice providing complete design
          solutions in interior-architecture and construction. The studio has
          worked on a diverse range of projects including individual houses,
          retail stores, offices, institutional projects and warehouses in
          different areas.
        </p>
        <p className={`${Styles.textwidth} text-gray-400 text-lg w-96`}>
          Effective space planning, clutter-free designs and precision in time
          management are the prominent features of each project and have become
          the hallmark of the studio.
        </p>
        <div className="flex mt-7 mb-20 items-center justify-center">
          <div
            className={`${Styles.numberBox} text-primary text-6xl font-bold w-32 h-32  lg:w-44 lg:h-44 flex justify-center items-center`}
          >
            <CountUp end={9} duration={2} />+
          </div>
          <p className="text-2xl lg:text-4xl w-32 ml-6 font-light">
            Years Experience Working
          </p>
        </div>
        {/* <div className=''>
        <button className='transition-all ease-linear delay-150 duration-300 rounded-xl w-48 h-12 bg-primary text-white font-semibold hover:text-black hover:bg-white'>
          <Link to='/aboutus'>View Works</Link>
        </button>
      </div> */}
      </div>
      <div className="relative px-10 mt-24 md:mt-0 flex">
        <img src="/assets/about2.jpeg" alt="img" className="w-full h-full" />
        <div className="absolute h-10 w-24 bg-white right-0 top-0"></div>
        <div className="absolute h-10 w-24 bg-white right-24 top-10"></div>
        <div className="absolute h-10 w-24 bg-white right-0 top-20"></div>
        <div className="absolute h-10 w-24 bg-white left-0 bottom-0"></div>
        <div className="absolute h-10 w-24 bg-white left-24 bottom-10"></div>
        <div className="absolute h-10 w-24 bg-white left-0 bottom-20"></div>
      </div>
    </div>
  );
};

export default AboutUs2;
