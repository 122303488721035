import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from '../components/commons/ScrollToTop';
import Aboutus from '../pages/Aboutus';
import Blogs from '../pages/Blogs';
import Contactus from '../pages/Contactus';
import ErrorPage from '../pages/ErrorPage';
import Homepage from '../pages/Homepage';
import ProjectDetails from '../pages/ProjectDetails';
import Projects from '../pages/Projects';
import ServiceDetail from '../pages/ServiceDetail';
import Services from '../pages/Services';

function PageRoutes() {
  return (
    <>
      <ScrollToTop />{' '}
      {/* get to the top of the page when we switch between pages */}
      <Routes>
        <Route exact path='/' element={<Homepage />} />
        <Route path='/aboutus' element={<Aboutus />} />
        <Route path='/contactus' element={<Contactus />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/services' element={<Services />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/services/:serviceTitle' element={<ServiceDetail />} />
        <Route path='/projects/:id' element={<ProjectDetails />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default PageRoutes;
