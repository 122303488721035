import React from 'react';
import { Link } from 'react-router-dom';
import ServiceContent from './ServiceContent';
// import ServiceSidebar from './ServiceSidebar';

function ServiceDetail1({ content, selectedService, setSelectedService }) {
  return (
    <div className='w-full flex flex-col items-center pt-16 pb-24'>
      <div className='w-5/6'>
        <Link to='/'>
          <button className='transition-all ease-linear border border-primary delay-150 duration-300 rounded-xl w-48 h-12 bg-primary text-white font-semibold hover:text-black hover:bg-white'>
            Back
          </button>
        </Link>
      </div>
      <div className='w-5/6 flex flex-col lg:flex-row item-center lg:items-start lg:justify-center mt-10 space-y-8 lg:space-y-0 lg:space-x-7'>
        {/* <ServiceSidebar
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        /> */}
        <ServiceContent content={content} />
      </div>
    </div>
  );
}

export default ServiceDetail1;
