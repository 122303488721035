import React from "react";

function ServiceContent({ content }) {
  return (
    <div className="w-full lg:w-1/2">
      <div className="flex flex-col space-y-3 text-gray-500 text-lg">
        <img
          src={content.img}
          className="w-full h-56 lg:h-96 object-fit rounded-lg"
          alt="service_img"
        />
        <p>{content.text}</p>
        <ul className="list-disc text-base pl-10">
          {content.description.map((des, index) => (
            <li key={index} className="pl-4">
              {des}
            </li>
          ))}
        </ul>
        <p className="text-2xl font-semibold text-gray-700 pb-3">
          Our Services in types of{" "}
          {content.title === "Engineering" ? "areas" : "building"}
        </p>

        {content.serviceTabs.map((des, index) => (
          <ul
            className={`${
              des.title.includes("-") ? "pl-12" : "list-disc pl-10 "
            } text-base`}
          >
            <li key={index} className="pl-4 -mt-3">
              {des.title}
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
}

export default ServiceContent;
