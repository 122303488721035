import React from 'react';
import HomePage2 from '../components/homepage/HomePage2';

function Services() {
  return (
    <div>
      <HomePage2 />
    </div>
  );
}

export default Services;
